import type {
  IAttribute,
  IContextSetValueModel,
  IDataService,
  IDataServiceService
} from "@/models/orchestration";
import type {
  BackRoute,
  IScorecard,
  IScorecardContentCategory,
  IScorecardGroup,
  IScorecardResult,
  MappedTreeTableCategory
} from "@/models/scorecards";
import { wordsFirstLetterToUpper } from "@/helpers/formatting";
import { computed, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useAuth } from "@/hooks/auth";
import type { IClient } from "@/models/clients";
import type { IPaginatedResponse } from "@/models/common";
import { PANEL_TYPE_TRIGGER } from "@/helpers/constants";
import { formatKnockoutReasons } from "@/helpers/scorecards";
import {
  getServiceAttributes,
  getServiceHelper,
  getServiceProviderDetailsHelper
} from "@/helpers/common";
import { useI18n } from "vue-i18n";

const actionsLoadingMap = ref<Record<string, boolean>>({});

export const useScorecardsBase = () => {
  const { getters } = useStore();
  const { t } = useI18n();

  const backRoute = computed<BackRoute>(() => getters["scorecards/backRoute"]);

  const activeScorecard = computed<IScorecard | null>(
    () => getters["scorecards/active"]
  );

  const activeScorecardGroup = computed<IScorecardGroup | null>(
    () => getters["scorecards/activeGroup"]
  );

  const scorecardsData = computed<IPaginatedResponse<IScorecard>>(
    () => getters["scorecards/all"]
  );

  const scorecardGroupsData = computed<IPaginatedResponse<IScorecardGroup>>(
    () => getters["scorecards/allGroups"]
  );

  const runScorecardIds = computed<string[]>(
    () => getters["scorecards/runScorecardIds"]
  );

  const isBuilderView = computed<boolean>(
    () => getters["scorecards/isBuilderView"]
  );

  const templateBuilderPhases = [
    t("SCORECARDS.PHASES.1"),
    t("SCORECARDS.PHASES.2"),
    t("SCORECARDS.PHASES.3"),
    t("SCORECARDS.PHASES.4"),
    t("SCORECARDS.PHASES.5")
  ];

  return {
    activeScorecard,
    activeScorecardGroup,
    scorecardsData,
    scorecardGroupsData,
    runScorecardIds,
    templateBuilderPhases,
    isBuilderView,
    backRoute
  };
};

export const useScorecards = (
  fetch = true,
  setValuesCallback?: (newScValue: IScorecard | IScorecardGroup) => void,
  include_content = false
) => {
  const { dispatch, getters } = useStore();
  const { activeScorecard, activeScorecardGroup } = useScorecardsBase();
  const { isClientAdmin, isClientUnderwriter, isAdmin } = useAuth();
  const route = useRoute();

  const isLoading = computed<boolean>(() => getters["scorecards/loading"]);
  const isGroupLoading = computed<boolean>(
    () => getters["scorecards/groupLoading"]
  );
  const isScorecardOrGroupLoading = computed<boolean>(
    () => isLoading.value || isGroupLoading.value
  );

  const isScorecardPage = computed(() =>
    [
      "ScorecardsTemplate",
      "ScorecardsBuilder",
      "ScorecardsReport",
      "ScorecardsOverview"
    ].includes(String(route.name))
  );

  const isTemplate = computed(() => route.name === "ScorecardsTemplate");

  const canUseScorecardActions = computed(() => {
    if (isAdmin) {
      return true;
    } else if (isClientAdmin || isClientUnderwriter) {
      const activeClient: IClient | null = getters["clients/active"];
      if (!activeClient) {
        return false;
      }
      return activeClient.can_manage_score_cards;
    } else {
      return false;
    }
  });

  const getScorecardOrScorecardGroup = async () => {
    const action = isScorecardPage.value
      ? "scorecards/get"
      : "scorecards/getGroup";
    if (actionsLoadingMap.value[action]) {
      return;
    }
    actionsLoadingMap.value[action] = true;
    try {
      await dispatch(
        action,
        isScorecardPage.value
          ? route.params.id
          : { id: route.params.id, include_content }
      );
    } finally {
      actionsLoadingMap.value[action] = false;
    }
  };

  const unwatch = watch(
    [activeScorecard, activeScorecardGroup],
    ([scorecardValue, screcardGroupValue]) => {
      if (!setValuesCallback) {
        unwatch();
        return;
      }
      if (isScorecardPage.value) {
        scorecardValue && setValuesCallback(scorecardValue);
        return;
      }
      screcardGroupValue && setValuesCallback(screcardGroupValue);
    }
  );

  onMounted(() => {
    if (!route.params.id || !fetch) {
      return;
    }
    getScorecardOrScorecardGroup();
  });

  return {
    activeScorecard,
    activeScorecardGroup,
    isLoading,
    isGroupLoading,
    isScorecardOrGroupLoading,
    isScorecardPage,
    getScorecardOrScorecardGroup,
    canUseScorecardActions,
    isTemplate
  };
};

export const useScorecardsServices = () => {
  const { getters, dispatch } = useStore();

  const services = computed<IDataService[] | null>(
    () => getters["scorecards/activeServices"]
  );

  const servicesWithoutTrigger = computed(
    () =>
      services.value?.filter(
        (service) => service.title.toLowerCase() !== PANEL_TYPE_TRIGGER
      ) || []
  );

  const serviceAttributes = computed(() =>
    servicesWithoutTrigger.value.reduce<IAttribute[]>(getServiceAttributes, [])
  );

  const getServiceName = (
    serviceId: string,
    context?: IContextSetValueModel
  ) => {
    if (!serviceAttributes.value?.length || !serviceId) {
      return "";
    }
    return (
      getServiceHelper(serviceId, serviceAttributes.value, context)?.name || ""
    );
  };

  const getServiceDescription = (
    serviceId: string,
    context?: IContextSetValueModel
  ) => {
    if (!services.value?.length || serviceId === undefined) {
      return "";
    }
    return (
      getServiceHelper(serviceId, serviceAttributes.value, context)
        ?.description || ""
    );
  };

  const getServiceProviderDetails = (
    serviceId?: string
  ): IDataServiceService | null => {
    if (!services.value?.length || !serviceId) {
      return null;
    }
    return getServiceProviderDetailsHelper(serviceId, services.value);
  };

  const getKnockoutReasons = (
    scorecard: Partial<IScorecard> & { scorecard_result?: IScorecardResult },
    attribute: { attributeOriginal?: string } | null = null
  ) => {
    let result_knockouts =
      scorecard.results?.[0]?.knockouts ||
      scorecard.scorecard_result?.knockouts ||
      [];
    if (attribute) {
      result_knockouts = result_knockouts.filter(
        (ko) => ko.category.attribute === attribute.attributeOriginal
      );
    }
    const knockouts = result_knockouts.map((ko) => {
      const serviceName = getServiceName(
        ko.category.attribute || "",
        ko.category.context
      );
      return formatKnockoutReasons(
        serviceName,
        ko.category.knockout_conditions
      );
    });
    return knockouts.join("\n");
  };

  const action = "scorecards/getServices";
  if (!actionsLoadingMap.value[action] && !services.value?.length) {
    actionsLoadingMap.value[action] = true;
    dispatch("scorecards/getServices").finally(
      () => (actionsLoadingMap.value[action] = false)
    );
  }

  return {
    services,
    servicesWithoutTrigger,
    serviceAttributes,
    getServiceName,
    getServiceDescription,
    getServiceProviderDetails,
    getKnockoutReasons
  };
};

const getBackupCalculatedPoints = (
  backup: IScorecardContentCategory,
  categories: IScorecardContentCategory
) => {
  if (!categories.backup_applied) {
    return backup.calculated_points || 0;
  }
  const backupApplied =
    categories.backup_applied?.attribute === backup.attribute;
  return backupApplied
    ? categories.backup_applied.calculated_points
    : backup.calculated_points || 0;
};

export const mapCategoriesForTreeTable = (
  categories: IScorecardContentCategory[] = [],
  scorecard: IScorecard | null,
  pageIsView: boolean,
  started: boolean
): MappedTreeTableCategory[] => {
  const { getServiceName, getServiceDescription } = useScorecardsServices();
  const rowClass = (data: IScorecardContentCategory): string | undefined => {
    if (started) {
      return data.calculated_points === 0 ||
        data.calculated_points === undefined
        ? "gray-row"
        : undefined;
    }
    return undefined;
  };
  const formatValue = (value: string | number | null | undefined) => {
    if (value === null) {
      return "null";
    }
    if (value === undefined) {
      return "-";
    }
    if (typeof value === "number") {
      return Number.parseFloat(String(value)).toFixed(2);
    }
    return wordsFirstLetterToUpper(String(value ?? 0));
  };
  return categories.map((cat, index): MappedTreeTableCategory => {
    const formattedPoints = computed(() => {
      return `${cat.calculated_points || 0}  ⁄ ${cat.points || 0} PTS`;
    });
    return {
      key: `${cat.attribute}-${index}`,
      data: {
        service: cat.attribute,
        attribute: getServiceName(String(cat.attribute || ""), cat.context),
        attributeOriginal: cat.attribute || "",
        context: cat.context,
        backup_applied: cat.backup_applied,
        index,
        info: getServiceDescription(String(cat.attribute || ""), cat.context),
        knockout: !!cat.knockout,
        knockout_conditions: cat.knockout_conditions || undefined,
        value: formatValue(cat.attribute_value),
        points: pageIsView ? `${cat.points} PTS` : formattedPoints.value,
        distribution:
          Math.round((cat.points / (scorecard?.points || 1)) * 100) + "%",
        notScored: cat.calculated_points === 0,
        color: cat.color || null
      },
      children: [
        ...(cat.backups?.map((child, childIndex) => {
          return {
            key: `${child.attribute}-${childIndex}`,
            data: {
              index: childIndex,
              backup: true,
              service: child.attribute,
              attribute: getServiceName(String(child?.attribute || "")),
              attributeOriginal: child.attribute,
              context: child.context,
              knockout: !!child.knockout,
              knockout_conditions: child.knockout_conditions || undefined,
              value: formatValue(
                (categories[index]?.backup_applied
                  ? categories[index].backup_applied?.attribute_value
                  : child.attribute_value) || 0
              ),
              points: `${getBackupCalculatedPoints(child, cat)} ⁄ ${
                child.points || 0
              } PTS`,
              distribution:
                Math.round((child.points / (scorecard?.points || 1)) * 100) +
                "%",
              color: child.color || null
            },
            styleClass: rowClass(cat)
          };
        }) || [])
      ],
      styleClass: rowClass(cat)
    };
  });
};
